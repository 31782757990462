@media only screen and (min-width: 1000px) {
 .login-container {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/Login/client_background.jpg");
  }
  .login-layer {
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .login-form {
    background-color: white;
    width: 30vw;
    height: 80vh;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-self: center;
    border-radius: 20px;
  }
  .login-input-style {
    width: 70%;
    align-self: center;
    background-color: white;
    margin-top: 20px !important;
  }
  .login-icon {
    color: #ff5722;
    font-size: 200px;
    align-self: center;
  }
  .login-button {
    width: 70%;
    align-self: center;
    height: 50px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .login-img{
    align-self: center;
    width: 20vw;
    height: 30vh;
  }
  .login-img-logo{
    align-self: center;
    width: 20vw;
    height: 15vh;
  }

}

  @media only screen and (max-width: 1000px) {
    /* For mobile phones: */
    .login-container {
      width: 100vw;
      height: 100vh;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../image/Login/client_background.jpg");
    }
    .login-layer {
      background-color: rgba(0,0,0,0.5);
      width: 100vw;
      height: 100vh;
      flex: 1;
      display: flex;
      justify-content: center;
      align-self: center;
    }
    .login-form {
      background-color: white;
      width: 300px;
      height: 533px;
      opacity: 0.8;
      display: flex;
      flex-direction: column;
      align-self: center;
      border-radius: 20px;
    }
    .login-input-style {
      width: 70%;
      align-self: center;
      background-color: white;
      margin-top: 8px !important;
    }
    .login-icon {
      color: #ff5722;
      font-size: 200;
      align-self: center;
    }
    .login-button {
      width: 70%;
      align-self: center;
      height: 40px;
      font-size: 16;
      font-weight: bold;
      margin-top: 8px;
    }
    .login-img{
      align-self: center;
      width: 200px;
      height: 200px;
    }
    .login-img-logo{
      align-self: center;
      width: 250px;
      height: 90px;
    }
  }